<script setup lang="ts">
import { RouterLink } from "vue-router";

const props = withDefaults(
  defineProps<{
    type?: "button" | "submit";
    color?: "primary" | "orange" | "light" | "nav" | "green" | "red" | "transparent" | "white" | "dark" | "main";
    size?: "sm" | "38" | "48" | "base" | "lg" | "xl" | "xs" | "xxs";
    variant?: "base" | "outline";
    to?: string;
  }>(),
  {
    type: "button",
    color: "primary",
    size: "base",
    variant: "base",
  }
);

const attrs = useAttrs();
const component = computed(() => (attrs.href ? "a" : props.to ? RouterLink : "button"));
const route = computed(() => (props.to ? props.to : undefined));
</script>

<template>
  <!-- eslint-disable -->
  <component
    :is="component"
    :to="route"
    :type="type"
    class="btn"
    :class="{
      'btn-primary': color === 'primary',
      'btn-orange': color === 'orange',
      'btn-light': color === 'light',
      'btn-green': color === 'green',
      'btn-white': color === 'white',
      'btn-dark': color === 'dark',
      'btn-main': color === 'main',
      'btn-transparent': color === 'transparent',
      'btn-red': color === 'red',
      'btn-nav': color === 'nav',
      f20: size === 'xl',
      'btn-size--base': size === 'base',
      'btn-size--lg': size === 'lg',
      'btn-size--sm': size === 'sm',
      'btn-size--xs': size === 'xs',
      'btn-size--xxs': size === 'xxs',
      'btn-size--48': size === '48',
      'btn-size--38': size === '38',
      'btn-variant--base': variant === 'base',
      'btn-variant--outline': variant === 'outline',
    }"
  >
    <slot />
  </component>
  <!-- eslint-enable -->
</template>

<style lang="scss">
.btn,
button {
  border: none !important;
  padding: 0;
  outline: none;
  border-radius: 4px;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  font-weight: 600;
  line-height: 1.2;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  transition: 0.3s;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &-white {
    background: #ffffff !important;
    color: #b4aed4;
  }

  &-dark {
    background: #e8e5ff !important;

    &:hover {
      filter: brightness(0.98) !important;
    }

    color: var(--color_secondary__100);
  }

  &-primary {
    background: linear-gradient(90deg, #7449e6 0.06%, #69a4ff 98.49%) !important;
    border-radius: 4px;
    color: white !important;
    padding: 12px;

    &:hover {
      filter: brightness(1.1);
    }
  }

  &-orange {
    background: linear-gradient(90deg, #f4a202 0%, #fac257 100%) !important;
    border-radius: 4px;
    color: #2c3034 !important;
    font-weight: 600;
    padding: 12px;

    &:hover {
      filter: brightness(1.1);
    }
  }

  &-transparent {
    background: transparent;
    color: var(--color_secondary__100) !important;
    font-weight: 600;
    border-radius: 4px;
    padding: 0 12px;

    &:hover {
      background: var(--bg_secondary__100) !important;
    }
  }

  &-main {
    background: var(--bg_main__100) !important;
    color: var(--color_secondary__100);
  }

  &-light {
    background: var(--bg_secondary__200) !important;
    color: var(--color_secondary__100) !important;
    font-weight: 600;
    border-radius: 4px;
    padding: 0 12px;

    &:hover {
      background: var(--bg_light_hover__100) !important;
    }

    &-gradient {
      background: var(--bg_light__100) !important;
      color: var(--color_secondary__100);
      font-weight: 600;
      border-radius: 4px;
      padding: 0 12px;
      position: relative;

      span {
        position: relative;
      }

      &:before {
        content: "";
        display: block;
        background: linear-gradient(90deg, #7449e6 0.06%, #69a4ff 98.49%);
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: inherit;
        opacity: 0;
        transition: 0.3s;
      }

      &:hover {
        color: white !important;

        &:before {
          opacity: 1;
        }
      }

      &.active {
        color: white;

        &:before {
          opacity: 1;
        }
      }
    }
  }

  &-nav {
    padding: 12px 16px;
    border-radius: var(--b-radius);
    font-weight: var(--fw-bold);
    letter-spacing: 0.02em;
    color: var(--color-nav);
    display: flex;
    align-items: center;
    background: transparent;

    &:hover {
      color: var(--primary-800);
    }

    &--active {
      background: var(--color_secondary__100);
      color: var(--primary-800);
    }
  }

  &-green {
    background: linear-gradient(90deg, #76ac41 3.33%, #97d25b) !important;
    color: #ffffff;
    font-weight: var(--fw-semibold);
    padding: 8px 12px;
  }

  &-red {
    background: #ff3030 !important;
    color: #ffffff;
  }

  &-variant {
    &--base {
    }

    &--outline {
      background: transparent;
      border-style: solid;
      border-width: 2px;
    }
  }
}

.btn {
  line-height: 1;

  &-size {
    &--base {
      padding: 14px 15px;
    }

    &--lg {
      padding: 15px 15px;
    }

    &--sm {
      padding: 9px 12px;
    }

    &--xs {
      padding: 6px 10px;
    }

    &--xxs {
      padding: 4px;
    }

    &--38 {
      height: 38px;
      padding: 0 12px;
      font-size: 14px;
      font-weight: 600;
    }

    &--48 {
      height: 48px;
      padding: 0 12px;
      font-size: 14px;
      font-weight: 700;
    }
  }
}

html.theme__dark {
  button.btn-dark {
    background: var(--bg_main__200) !important;

    &:hover {
      background: var(--bg_main__300);
    }
  }

  .btn-white {
    background: var(--bg_main__200);
    color: var(--color_secondary__100);

    &:hover {
      background: var(--bg_main__100);
    }
  }
}

div.bt,
div.btn {
  cursor: default;
}

.btn {
  .ondesk {
    display: none;

    @media screen and (min-width: 980px) {
      display: inline;
    }
  }

  .onmob {
    display: inline;

    @media screen and (min-width: 980px) {
      display: none;
    }
  }
}
</style>
